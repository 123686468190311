export default {
  "hello_aquary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello Aquary"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
  "ChooseAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ChooseAddress"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categories"])},
  "Choose_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose_category"])},
  "price_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price from"])},
  "price_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price to"])},
  "enter_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter_text"])},
  "Loading...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
  "bestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["best Seller"])},
  "featured_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["featured properties"])},
  "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view All"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
  "yourmessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your message"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
  "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["features"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
  "plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plans"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["share"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["map"])},
  "Property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Details"])},
  "choice_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice images"])},
  "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
  "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space"])},
  "relatedProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["related Properties"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "MakeProperty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Property"])},
  "properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["properties"])},
  "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["properties"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])},
  "makeadvertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["make advertise"])},
  "Ar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
  "En": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Site_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site map"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm Password"])},
  "Rememberme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
  "registernow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit profile"])},
  "edit_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit password"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["old password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new password"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team member"])},
  "Blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogs"])},
  "SingleService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Details"])},
  "BlogsMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Blogs"])},
  "Started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started Now"])},
  "ServicesMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Services"])},
  "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["useful links"])},
  "SingleBlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Blog"])},
  "Follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow on Socials"])},
  "Message_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Sent Successfully"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm password"])},
  "profile_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profile updated"])},
  "no_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter result not aviable"])},
  "favorite_properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favorite properties"])}
}